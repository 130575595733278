var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.edit-text", { text: _vm.$t("lbl_contact") })
      }
    },
    [_c("FormContact", { attrs: { "contact-id": _vm.contactId } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }