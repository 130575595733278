






import { FormContact } from "@/components/ContactData";
import Vue from "vue";

export default Vue.extend({
  name: "ContactEditPage",
  components: {
    FormContact,
  },
  data() {
    return {
      contactId: "",
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.contactId = this.$route.params.id;
    }
  },
});
